import moment, { Moment } from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  DateTimePickerComponent,
  Inject,
} from "@syncfusion/ej2-react-calendars";
import { useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import { populate } from "./HourlyAQISlice";
import { VscLoading } from "react-icons/all";
import { Redirect } from "react-router";
import { changeDevice } from "../../app/InputSlice";
import { enableRipple } from "@syncfusion/ej2-base";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  ExcelExport,
  Filter,
  Resize,
  Sort,
  FilterSettingsModel,
  ToolbarItems,
  PdfExport,
  Search,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { getHourAvgHistorical } from "../../api/getHourAvgHistorical";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import { addSerialId } from "../../util/addIndex";
import { sortComparator } from "../../util/sortComparator";
import { getVocHourAvgHistorical } from "../../api/getVocHourAvgHistorical";

enableRipple(true);
const HourlyAQI: React.FC<{}> = () => {
  const [HourlyVoc, setHourlyVoc] = useState<any[]>([]);
  const rowIndexValue = useRef<number>(0);
  const devices = useSelector((state: RootState) => state.devices);
  const data = useSelector((state: RootState) => state.hourly);
  const [StartDateTime, setStartDateTime] = useState<Moment>(
    moment().subtract(1, "day")
  );
  const [EndDateTime, setEndDateTime] = useState<Moment>(moment());
  console.log(devices);
  const dispatch = useAppDispatch();
  const { device } = useSelector((root: RootState) => root.input);
  const [LoadingData, setLoadingData] = useState<boolean>(false);
  const toolbar: ToolbarItems[] = [
    "ExcelExport",
    // "PdfExport",
    "CsvExport",
    "Search",
    // "Print",
  ];
  const filterOptions: FilterSettingsModel = {
    type: "Excel",
  };
  const toolbarClick = (args: ClickEventArgs | undefined) => {
    if (gridref.current === null || !args) return;
    console.log("Event Type: ", args.item);
    switch (args.item.text) {
      case "Excel Export":
        rowIndexValue.current = 0;
        gridref.current.excelExport({
          fileName: `${device?.location}-${StartDateTime.format(
            "ll"
          )}-${EndDateTime.format("ll")}.xlsx`,
        });
        break;
      case "PDF Export":
        rowIndexValue.current = 0;
        gridref.current.pdfExport({
          fileName: `${device?.location},-${StartDateTime.format(
            "ll"
          )}-${EndDateTime.format("ll")}.pdf`,
          theme: {
            header: {
              fontSize: 12,
              bold: true,
            },
            record: {
              fontSize: 11,
            },
          },
          pageOrientation: "Landscape",
          // header: {
          //   contents: [
          //     {
          //       type: "Text",
          //       value: `Device Location: ${device?.location}, ${
          //         device?.street
          //       } | Device Id: ${device?.dev_id} | Start Date: ${moment(
          //         StartDate
          //       ).format("ll")} | EndDate: ${EndDateTime.format("ll")}`,
          //     },
          //   ],
          // },
          // footer: {
          //   contents: [
          //     {
          //       type: "PageNumber",
          //       pageNumberType: "Arabic",
          //     },
          //   ],
          // },
        });
        break;
      case "CSV Export":
        rowIndexValue.current = 0;
        gridref.current.csvExport({
          fileName: `${device?.location},-${StartDateTime.format(
            "ll"
          )}-${EndDateTime.format("ll")}.csv`,
        });
        break;
    }
  };
  useEffect(() => {
    const callAPI = async () => {
      if (device === null) return;
      else if (device.dev_id === "ab42c3fb27d7ee0ba9832ff23a079d5b") {
        const data = await getVocHourAvgHistorical(StartDateTime, EndDateTime);
        const newData = data?.data?.data.map((i, index) => ({
          ...i,
          serial: index + 1,
        }));
        setHourlyVoc(newData);
      } else {
        const data = await getHourAvgHistorical(
          device.loc_id,
          StartDateTime,
          EndDateTime
        );
        dispatch(populate(addSerialId(data.data.data)));
      }
    };
    callAPI();
  }, []);
  const gridref = useRef<GridComponent | null>(null);
  const { state } = useSelector((state: RootState) => state.user);
  if (state !== "loggedIn") return <Redirect to="/signin" />;

  return (
    <>
      <div className="flex flex-col md:flex-row gap-4 justify-start items-center max-w-full bg-blue-400 rounded-md p-4 m-2 text-gray-100 font-semibold font-sans text-sm shadow-lg">
        {/* <AQIBadge aqi={dailyaqi[0]?.AQI} /> */}
        {/* {device?.location} */}
        <span className="flex flex-1 flex-row justify-start items-stretch text-left gap-4 shadow-lg rounded-md bg-transparent m-0 p-3">
          <div className="my-auto pl-2 text-center">Device</div>
          <select
            name="devices"
            id="devices"
            className="p-2 flex-1 outline-none bg-transparent rounded-md text-gray-50 border-0 rounded-l-none placeholder-gray-800 border-l-2 border-gray-300"
            defaultValue="null"
            onChange={(e) => {
              const newDevice = devices.find(
                (d) => d.dev_id === e.currentTarget.value
              );
              if (!!newDevice) dispatch(changeDevice(newDevice));
            }}
            value={device?.dev_id}
          >
            <option value="null" className="text-gray-100 bg-blue-400" disabled>
              Select Any
            </option>
            {devices.map((d) => (
              <option value={d.dev_id} className="text-gray-100 bg-blue-400">
                {d.location}
              </option>
            ))}
          </select>
        </span>
        <span className="flex flex-1 flex-row justify-start items-stretch text-left gap-4 shadow-lg rounded-md bg-transparent m-0 p-3">
          <div className="m-auto pl-2 text-center">Start DateTime</div>
          <DateTimePickerComponent
            id="startdatetimepicker"
            placeholder="Select a start date and time"
            value={StartDateTime.toDate()}
            change={(e) => {
              setStartDateTime(moment(e.value));
            }}
            openOnFocus={true}
          />
        </span>
        <span className="flex flex-1 flex-row justify-start items-stretch text-left gap-4 shadow-lg rounded-md bg-transparent m-0 p-3">
          <div className="m-auto pl-2 text-center">End DateTime</div>
          <DateTimePickerComponent
            id="enddatetimepicker"
            placeholder="Select an end date and time"
            value={EndDateTime.toDate()}
            change={(e) => {
              setEndDateTime(moment(e.value));
            }}
          />
        </span>
        <span
          className="flex flex-row justify-start items-stretch text-left gap-4 shadow-lg rounded-md bg-gray-200 text-blue-600 m-0 p-3 px-8"
          onClick={async () => {
            setLoadingData(true);
            if (device === null) return;
            else if (device.dev_id === "ab42c3fb27d7ee0ba9832ff23a079d5b") {
              const data = await getVocHourAvgHistorical(
                StartDateTime,
                EndDateTime
              );
              const newData = data?.data?.data.map((i, index) => ({
                ...i,
                serial: index + 1,
              }));
              setHourlyVoc(newData);
            } else {
              const data = await getHourAvgHistorical(
                device.loc_id,
                StartDateTime,
                EndDateTime
              );
              dispatch(populate(addSerialId(data.data.data)));
            }

            setLoadingData(false);
            if (gridref.current !== null) gridref.current.refresh();
            console.log("dispatching data");
          }}
        >
          <div className="m-auto mx-auto text-center">
            {LoadingData ? (
              <VscLoading className="animate-spin" size="30px" />
            ) : (
              "Find"
            )}
          </div>
        </span>
      </div>
      <GridComponent
        dataSource={
          device?.dev_id !== "ab42c3fb27d7ee0ba9832ff23a079d5b"
            ? data.map((k) => ({
                ...k,
                lat: device?.lat,
                long: device?.long,
                location: device?.location,
                district: device?.district,
              }))
            : HourlyVoc
        }
        ref={(r) => (gridref.current = r)}
        style={{ marginBottom: 60 }}
        toolbar={toolbar}
        toolbarClick={toolbarClick}
        enableStickyHeader
        allowExcelExport
        allowFiltering
        allowSorting
        allowPdfExport
        allowResizing
        enableAltRow
        enableHover
        filterSettings={filterOptions}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="serial"
            headerText="Serial"
            allowFiltering={false}
            width="100px"
          />
          <ColumnDirective headerText="Location" field="location" />
          <ColumnDirective headerText="District" field="district" />
          <ColumnDirective field="date" headerText="Date" />
          <ColumnDirective field="hour" headerText="Hour" />
          <ColumnDirective headerText="Latitude" field="lat" />

          <ColumnDirective headerText="Longitude" field="long" />
          {device?.dev_id === "ab42c3fb27d7ee0ba9832ff23a079d5b" && (
            <ColumnDirective
              sortComparer={sortComparator}
              field="voc_avg"
              headerText={"VOC AVG (µg/m³)"}
            />
          )}

          <ColumnDirective
            sortComparer={sortComparator}
            field="no2"
            headerText={"CO AVG (ppb)"}
          />
          <ColumnDirective
            sortComparer={sortComparator}
            field="so2"
            headerText={"SO2 AVG (µg/m³)"}
          />
          {/* <ColumnDirective
            sortComparer={sortComparator}
            field="pm1_avg"
            headerText={"PM 1 AVG (µg/m³)"}
          /> */}
          <ColumnDirective
            sortComparer={sortComparator}
            field="pm25"
            headerText={"PM 2.5 AVG (µg/m³)"}
          />
          <ColumnDirective
            sortComparer={sortComparator}
            field="pm10"
            headerText={"PM 10 AVG (µg/m³)"}
          />
          <ColumnDirective
            sortComparer={sortComparator}
            field="ext_humi"
            headerText={"REL HUMI (%)"}
          />
          <ColumnDirective
            sortComparer={sortComparator}
            field="ext_temp"
            headerText={"TEMPERATURE (°C)"}
          />
        </ColumnsDirective>
        <Inject
          services={[
            Sort,
            Filter,
            Resize,
            ExcelExport,
            PdfExport,
            Toolbar,
            Search,
          ]}
        />
      </GridComponent>
    </>
  );
};

export default HourlyAQI;
